import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

function Home() {
  
  


  return (
    <div className='body'>
      <br />
      <div className='header'>
        <div className='row'>
          <a href="#" className='col-sm-2 headCols firstCol hov '>
            <u>
             Home
            </u>
          </a>
          <a href="#" className='col-sm-2 headCols hov'>
            Projects
          </a>
          <a href="#" className='col-sm-2 headCols hov'>
            Resume
          </a>
          <a href="#" className='col-sm-2 headCols lastCol hov'>
            Documents
          </a>
        </div>
      </div>
      
      <div className="padded row">
        <div className="col-sm-4">
            
            <img src={logo} style={{padding: "1%",width: "80%", margin: "auto", display:"block"}} />
            <hr style={{width:"90%", margin: "auto"}} />
            <h3>Current projects I am working on</h3>
            <hr style={{width:"90%", margin: "auto"}} />
            <h4><a href="7.senior.html">Senior Project</a></h4>
            <h4><a href="5.cardinal-eats.html">Cardinal Eats Club President</a></h4>
    
        </div>
        <div className="col-sm-8 d-flex align-items-center">
            <span>
                <p className='padded'>
                    <h4>I am a senior in Cyber Security Engineering at Iowa State University. I am interested in Engineering cyber systems and Forensic Investigations. I just completed working as a Security Intern at Principal Financial Group working on "Blue Team" Engineering work.</h4>
                    <br></br>
                    <h3><a href="https://templeton.pro\pub\TempletonResumeSeptember2023.pdf">Here is a link to my resume</a></h3>
                    <br></br>
                    <h4>Thanks for taking a look!</h4>
                </p>
            </span>
        </div>
        
      </div>
      <div className='footer'>
        <div className='row'>
            <a className='col-sm-2'>
            </a>
            <a href="#" className='col-sm-2 headCols firstFoot hov '>
                <u>
                    Home
                </u>
            </a>
            <a href="#" className='col-sm-2 headCols hov'>
              Projects
            </a>
            <a href="#" className='col-sm-2 headCols hov'>
              Resume
            </a>
            <a href="#" className='col-sm-2 headCols hov'>
              Documents
            </a>
        </div>
        <div className='row'>
            <a className='col-sm-4'>
            </a>
            <a href="mailto:jstemps02@gmail.com" className='col-sm-4 headCols hov small'>
              <h3>Email Me</h3>
            </a>
           
        </div>
        <div className='row'>

          <div className='col-md-4'></div>
          <a className='col-md-2 headCols hov' target="_blank" href="https://www.github.com/jstemps">
              <img src={git} alt="GitHub" width="50px" />
          </a>
          <a className='col-md-2 headCols lastCol hov' target="_blank" href="https://www.linkedin.com/in/justin-templeton/">
              <img src={li} alt="LinkedIn" width="50px" />
          </a>
         

        </div>  
      </div>

    </div>
  );
}

export default Home;
